import { NavigateFunction } from "react-router-dom";
import axiosInstance from "../Interceptor/interceptor.ts";

export const fetchTenantList = async () => {
  try {
    const response = await axiosInstance.get("/tenant/list");
    // console.log("GET Request Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Request Error:", error);
  }
};

export const login = async (
  selectedTenantId: number,
  email: string,
  password: string,
  navigate: NavigateFunction,
  showSuccessToast: (message: string) => void,
  showErrorToast: (message: string) => void
) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+/;
  let isEmail = false;

  if (email.match(emailRegex)) {
    isEmail = true;
  }

  const loginData = {
    email: isEmail ? email : "",
    username: isEmail ? "" : email,
    tenantId: selectedTenantId,
    password,
  };

  try {
    const response = await axiosInstance.post("/auth/login", loginData);
    if (response.data.user_role === "admin") {
      // console.log("Login response", response);

      // Store the token and user_role in session storage
      const storedToken = response.data.token;
      const storedOrgName = response.data.orgName;
      const storedUserRole = response.data.user_role;

      sessionStorage.setItem("token", storedToken);
      sessionStorage.setItem("user_role", storedUserRole);
      sessionStorage.setItem("orgName", storedOrgName);

      navigate("/");
      showSuccessToast("Login successful!");
      // window.location.reload(false);
    } else {
      showErrorToast("You are not authorized. Only admins can log in.");
    }
  } catch (error) {
    // console.log("Login error:", error.response.data);
    // const errorResponse = error.response.data;
    showErrorToast(`Login failed: ${error.response.data.error}`);
    throw error;
  }
};
