import React, { useEffect, useState } from "react";
import { fetchUserProfile } from "../API/controller/user_profile";
import { Link } from "react-router-dom";

interface UserProfileData {
  user_profile: {
    name: string;
    department: string;
    role: string;
    organizationName: string;
    supervisor: string;
  };
}

const UserProfile: React.FC = () => {
  const [profile, setProfile] = useState<UserProfileData | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetchUserProfile();
        // console.log("ress", response);
        setProfile(response);
        // console.log("response user Profile ", profile);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid ">
          <div className="page-header ">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Profile</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card mb-0">
            <div className="card-body background-imgcolor">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <a>
                          <img
                            src="https://dosrdm61w7ws7.cloudfront.net/App-icon/logo2.png"
                            alt="User Image"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-7">
                          <h3 className="user-name m-t-0 mb-2">
                            {profile?.user_profile?.name}
                          </h3>
                          <ul className="personal-info">
                            <li>
                              <div className="title">Department:</div>
                              <div className="text">
                                {profile?.user_profile?.department}
                              </div>
                            </li>

                            <li>
                              <div className="title">User Role:</div>
                              <div className="text">
                                {profile?.user_profile?.role}
                              </div>
                            </li>
                            <li>
                              <div className="title">Organisation:</div>
                              <div className="text">
                                {profile?.user_profile?.organizationName}
                              </div>
                            </li>
                            <li>
                              <div className="title">Supervisor:</div>
                              <div className="text">
                                {profile?.user_profile?.supervisor}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
