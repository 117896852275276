// import { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "../googlebutton.css";

import { useNavigate } from "react-router-dom";
const SideBar = () => {
  const navigate = useNavigate();
  const orgName = sessionStorage.getItem("orgName");
  const user = sessionStorage.getItem("user_role");

  // const [hideSideBar,setHideSideBar] = useState(false)

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user_role");
    sessionStorage.removeItem("orgName");

    navigate("/login");
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="header">
          <div
            className="page-title-box header-left "
            style={{ cursor: "default" }}
          >
            {orgName && <h4 style={{ color: "white" }}>{orgName}</h4>}
            {/* <div className="page-title-box logo"></div> */}
            <Link to="/" className="logo">
              {/* <img
                src="https://dosrdm61w7ws7.cloudfront.net/App-icon/logo2.png"
                width={40}
                height={40}
                alt="Logo"
              /> */}
            </Link>
            <Link to="/" className="logo2">
              <img
                src="https://dosrdm61w7ws7.cloudfront.net/App-icon/logo2.png"
                width={40}
                height={40}
                alt="Logo"
              />
            </Link>

            {/* <div className="page-title-box text-center">
      <h3>AIMS</h3>
    </div> */}
          </div>
          {/* <a id="toggle_btn" href="javascript:void(0);">
            <span className="bar-icon">
              <span />
              <span />
              <span />
            </span>
          </a> */}
          {/* <div className="page-title-box">{orgName && <h3>{orgName}</h3>}</div> */}
          <a id="mobile_btn" className="mobile_btn" href="#sidebar">
            <i className="fa-solid fa-bars" />
          </a>
          <ul className="nav user-menu">
            <li className="nav-item">
              <div className="top-nav-search">
                <a href="javascript:void(0);" className="responsive-search">
                  <i className="fa-solid fa-magnifying-glass" />
                </a>
              </div>
            </li>

            <li className="nav-item dropdown has-arrow main-drop">
              <a className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                <span className="user-img" style={{ marginRight: "5px" }}>
                  <img
                    src="https://dosrdm61w7ws7.cloudfront.net/App-icon/logo2.png"
                    alt="User Image"
                  />
                  <span className="status online" />
                </span>
                <span style={{ textTransform: "capitalize" }}>{user}</span>
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/profile">
                  My Profile
                </Link>
                {/* <a className="dropdown-item" >
                  Settings
                </a> */}
                <Link
                  className="dropdown-item"
                  to="/login"
                  onClick={handleLogout}
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
          <div className="dropdown mobile-user-menu">
            <a
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-solid fa-ellipsis-vertical" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link to="/profile" className="dropdown-item">
                My Profile
              </Link>
              {/* <a className="dropdown-item">Settings</a> */}
              <Link className="dropdown-item" to="/login">
                Logout
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar" id="sidebar">
          <div className="sidebar-inner slimscroll">
            <div className="sidebar-menu">
              <nav className="greedys sidebar-horizantal">
                <ul className="list-inline-item list-unstyled links">
                  {/* <li className="menu-title">
              <span>Main</span>
             </li> */}
                  {/* <li className="submenu"> */}
                  {/* <a href="">
                      <i className="la la-dashboard" /> <span> Dashboard</span>{" "}
                      <span className="menu-arrow" />
                    </a> */}
                  <ul>
                    <li>
                      <Link to="/">Admin Dashboard</Link>
                    </li>
                    <li>
                      <Link to="/">Employee Dashboard</Link>
                    </li>
                  </ul>
                  {/* </li> */}

                  <li className="menu-title">{/* <span>Employees</span> */}</li>
                  <li className="submenu">
                    <a className="noti-dot">
                      <i className="la la-user" /> <span> Employee Master</span>{" "}
                      <span className="menu-arrow" />
                    </a>
                    <ul style={{ display: "block !important" }}>
                      {/* <li>
                        <Link href="employees.html">All Employees</Link>
                      </li> */}
                      <li>
                        <Link to="/addemployee">Add Employee</Link>
                      </li>

                      <li>
                        <Link to="/employeelist">All Employee</Link>
                      </li>

                      <li>
                        <Link to="/employeeleavebal">Leave Balance </Link>
                      </li>
                      <li>
                        <NavLink to="/attendencereport">
                          Attendance report{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/outofrange">Out of Range </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <ul>
                <li>
                  <a>
                    <i
                      className="la la-dashboard"
                      style={{ color: "rgb(162 238 244)", cursor: "default" }}
                    ></i>{" "}
                    <span
                      onClick={() => window.location.reload()}
                      style={{ color: "rgb(162 238 244)" }}
                    >
                      Admin Panel
                    </span>
                  </a>
                </li>
                {/* <li className="submenu">
                  <a href="" className="subdrop">
                    <i
                      className="la la-dashboard"
                      style={{ color: "rgb(162 238 244)" }}
                      // style={{ color: "#71fde8" }}
                    />{" "}
                    <span style={{ color: "rgb(162 238 244)" }}>
                      {" "}
                      Dashboard
                    </span>{" "}
                    <span className="menu-arrow" />
                  </a>
                </li> */}
                <ul>
                  <li>
                    <NavLink to="/">Admin Dashboard</NavLink>
                  </li>
                </ul>

                <hr />
                <li className="menu-title">
                  <span></span>
                </li>
                <li>
                  <a>
                    <i
                      className="la la-user"
                      style={{ color: "rgb(162 238 244)" }}
                    ></i>{" "}
                    <span
                      style={{ color: "rgb(162 238 244)", cursor: "default" }}
                    >
                      {" "}
                      Employee Master
                    </span>
                  </a>
                </li>
                <ul>
                  <li>
                    <NavLink to="/addemployee">Add Employee</NavLink>
                  </li>
                  <li>
                    <NavLink to="/employeelist">All Employees</NavLink>
                  </li>

                  <li>
                    <NavLink to="/employeeleavebal">Leave Balance </NavLink>
                  </li>
                  <li>
                    <NavLink to="/attendencereport">Attendance Report </NavLink>
                  </li>
                  <li>
                    <NavLink to="/outofrange">Out of Range </NavLink>
                  </li>
                </ul>
                {/* <li className="submenu">
                  <a className="" style={{ color: "rgb(162 238 244)" }}>
                    <i className="la la-user" />{" "}
                    <span style={{ color: "rgb(162 238 244)" }}>
                      {" "}
                      Employee Master
                    </span>{" "}
                    <span className="menu-arrow" />
                  </a>
                </li> */}
              </ul>
              <div
                className="logo-container"
                style={{ position: "absolute", bottom: "10px" }}
              >
                <ul>
                  <a
                    href="
                    https://apps.apple.com/in/app/smart-aims/id6469505895"
                    className="storeLink"
                  >
                    <img
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                      alt="Download on the App Store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.smartaims.aims"
                    className="storeLink"
                  >
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                      style={{ width: "128%", height: "50px" }}
                    />
                  </a>
                </ul>
              </div>
              <div
                className="footer fixed-bottom text-secondary"
                style={{ paddingLeft: "15px" }}
              >
                <span className="text-align-center">&copy; V1.0.8</span>
              </div>
            </div>
          </div>
        </div>

        <Outlet />
      </div>
    </>
  );
};

export default SideBar;
