// import Leaves from "../components/Leaves";
import UserChart from "../components/UserChart";
import DoughnutChart from "../components/DoughnutChart";
import BarChart from "../components/BarChart";
import { useEffect, useState } from "react";
import axios from "axios";
// import PopupMessage from "../components/PopupMessage";
import { fetchAttendenceCount } from "../API/controller/attendencecount";
import LeaveRequest from "../components/LeaveRequest";
import { fetchViewLeaveRequest } from "../API/controller/view_leave_request";
import ProjectStatistics from "../components/ProjectStatistics";
import TaskStatistics from "../components/TaskStatistics";
import { fetchEmpLeaveBal } from "../API/controller/emp_leave_balance";
import TodaysAttendence from "../components/TodaysAttendence";

interface LeaveBalanceItem {
  // Define the properties of each leave balance item
  leaveType: string;
  balance: number;
}

interface LeaveBalResponse {
  // Define the property 'leaveBalance' as an array of LeaveBalanceItem
  leaveBalance: LeaveBalanceItem[];
}

const AdminDashboard: React.FC = () => {
  // const [tenants, setTenants] = useState<any[]>([]);
  const [organisationDetail, setOrganisationDetail] = useState<any>([]);
  // @ts-ignore
  const [viewLeavesRequest, setViewLeavesRequest] = useState([]);
  // @ts-ignore
  const [leaveBal, setLeaveBal] = useState<LeaveBalResponse | undefined>(
    undefined
  );
  // const [showPopup, setShowPopup] = useState(true);
  // const [error, setError] = useState<string | null>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tenant = await axios.get("https://sm.oortfy.com/v1/tenant/list");
        const organisation = await fetchAttendenceCount();
        const leaveRequest = await fetchViewLeaveRequest();
        // const approveReq = await fetchApproveLeaves();
        const leavebal = await fetchEmpLeaveBal();
        // @ts-ignore
        const [tenants, organisationDetailss, viewLeaveDetails, empLeaveBal] =
          await Promise.all([tenant, organisation, leaveRequest, leavebal]);

        // setTenants(tenants.data);
        // console.log("tenant data", tenants);
        setOrganisationDetail(organisationDetailss);
        // console.log(" organisation", organisationDetail);

        setViewLeavesRequest(viewLeaveDetails);
        // console.log("view leaves report", viewLeavesRequest);
        setLeaveBal(empLeaveBal);

        // console.log("emp leave bal", empLeaveBal);
        // console.log("emp leave bal", leaveBal);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                {/* <h3 className="page-title text-white">Dashboard</h3> */}
                {/* <ul className="breadcrumb">
                <li className="breadcrumb-item active">Dashboard</li>
              </ul> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget background-imgcolor">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <i className="fa-solid fa-screwdriver-wrench"></i>
                  </span>
                  <div className="dash-widget-info">
                    {organisationDetail && (
                      <h3>
                        {organisationDetail?.organisationDetails?.projectsCount}
                      </h3>
                    )}
                    <span>Projects</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget background-imgcolor">
                <div className="card-body ">
                  <span className="dash-widget-icon">
                    <i className="fa-solid fa-users "></i>
                  </span>
                  <div className="dash-widget-info">
                    {organisationDetail && (
                      <h3>
                        {organisationDetail.organisationDetails?.totalAdmins}
                      </h3>
                    )}
                    <span>Admins</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget background-imgcolor">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <i className="fa-solid fa-user-tie"></i>
                  </span>
                  <div className="dash-widget-info">
                    {organisationDetail && (
                      <h3>
                        {
                          organisationDetail?.organisationDetails
                            ?.totalSupervisors
                        }
                      </h3>
                    )}
                    <span>Supervisors</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget background-imgcolor">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <i className="fa-solid fa-user" />
                  </span>
                  <div className="dash-widget-info">
                    {organisationDetail && (
                      <h3>
                        {
                          organisationDetail?.organisationDetails
                            ?.totalEmployees
                        }
                      </h3>
                    )}
                    <span>Employees</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-4 d-flex text-center">
                  <div
                    className="card background-imgcolor"
                    style={{ width: "100%" }}
                  >
                    <div className="card-body ">
                      <h3 className="card-title">Attendance</h3>
                      {organisationDetail && (
                        <UserChart
                          chartData={organisationDetail?.organisationDetails}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <LeaveRequest
                  absentData={organisationDetail?.organisationDetails}
                />
                <TaskStatistics />
              </div>
            </div>
          </div>
          <div className="row">
            <ProjectStatistics />
            <div className="col-md-12 col-lg-4 col-xl-3 d-flex text-center">
              <div className="card ">
                <div className="text-mid">Features Coming Soon!</div>
                <div className="card-body blur">
                  <h3 className="card-title">Leaves</h3>
                  <DoughnutChart />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xl-5 text-center">
              <div className="card " style={{ paddingBottom: "115px" }}>
                <div className="text-mid">Features Coming Soon!</div>
                <div className="card-body blur ">
                  <h3 className="card-title" style={{ marginBottom: "-10px" }}>
                    Leaves
                  </h3>
                  <BarChart />
                </div>
              </div>
            </div>
          </div>

          <TodaysAttendence />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
