import axiosInstance from "../Interceptor/interceptor";

export const fetchEmpLeaveBal = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is missing");
      return;
    }

    const response = await axiosInstance.get(
      "/leaves/employees-leave-balance",
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    // console.log("Get Emp Leave Balance", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Request Error:", error);
  }
};
