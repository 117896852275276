const ProjectStatistics = () => {
  return (
    <div className="col-md-12 col-lg-12 col-xl-4 d-flex">
      <div className="card flex-fill dash-statistics">
        <div className="text-mid">Features Coming Soon!</div>

        <div className="card-body blur">
          <h5 className="card-title">Project Statistics</h5>
          <div className="stats-list">
            <div className="stats-info">
              <p>
                Today Leave{" "}
                <strong>
                  4 <small>/ 65</small>
                </strong>
              </p>
              <div className="progress">
                <div
                  className="progress-bar bg-primary w-31"
                  role="progressbar"
                  aria-valuenow={31}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
            <div className="stats-info">
              <p>
                Pending Invoice{" "}
                <strong>
                  15 <small>/ 92</small>
                </strong>
              </p>
              <div className="progress">
                <div
                  className="progress-bar bg-warning w-31"
                  role="progressbar"
                  aria-valuenow={31}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
            <div className="stats-info">
              <p>
                Completed Projects{" "}
                <strong>
                  85 <small>/ 112</small>
                </strong>
              </p>
              <div className="progress">
                <div
                  className="progress-bar bg-success w-62"
                  role="progressbar"
                  aria-valuenow={62}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
            <div className="stats-info">
              <p>
                Open Tickets{" "}
                <strong>
                  190 <small>/ 212</small>
                </strong>
              </p>
              <div className="progress">
                <div
                  className="progress-bar bg-danger w-62"
                  role="progressbar"
                  aria-valuenow={62}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
            <div className="stats-info">
              <p>
                Closed Tickets{" "}
                <strong>
                  22 <small>/ 212</small>
                </strong>
              </p>
              <div className="progress">
                <div
                  className="progress-bar bg-info w-22"
                  role="progressbar"
                  aria-valuenow={22}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectStatistics;
