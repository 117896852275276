import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

interface UserChartProps {
  chartData: {
    presentEmployees: number;
    absentEmployees: number;
  };
}

ChartJS.register(ArcElement, Tooltip, Legend);

const UserChart: React.FC<UserChartProps> = ({ chartData }) => {
  const data = {
    labels: ["Present Employees", "Absent Employees"],
    datasets: [
      {
        label: "Attendance",
        data: [chartData?.presentEmployees, chartData?.absentEmployees],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        legend: ["a", "b"],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <>
      <Pie data={data} />
    </>
  );
};

export default UserChart;
