import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClockLoader } from "react-spinners";
import { format } from "date-fns";
import {
  AttendanceReportItem,
  fetchAttendenceReport,
} from "../API/controller/new_attendence_report";

import "../App.css";
import * as ExcelJS from "exceljs";
import { Link } from "react-router-dom";

const AttendenceReport: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [attendanceReport, setAttendanceReport] = useState<
    AttendanceReportItem[]
  >([]);
  const [fromDateValidationError, setFromDateValidationError] = useState<
    string | null
  >(null);
  const [toDateValidationError, setToDateValidationError] = useState<
    string | null
  >(null);
  const [noEmployeePresent, setNoEmployeePresent] = useState<boolean>(false);
  const [backendMessage, setBackendMessage] = useState<string | null>(null);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  // @ts-ignore
  const [dayOfWeek, setDayOfWeek] = useState<string>("");
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [dropdown, setDropdown] = useState<AttendanceReportItem[]>([]);

  async function handleSearch() {
    setNoEmployeePresent(false);
    setBackendMessage(null);
    setLoading(true);

    if (fromDate && toDate) {
      setFromDateValidationError(null);
      setToDateValidationError(null);

      try {
        const formattedFromDate = format(fromDate, "yyyy-MM-dd");
        const formattedToDate = format(toDate, "yyyy-MM-dd");

        const response = await fetchAttendenceReport(
          formattedFromDate,
          formattedToDate
        );
        // console.log("response data", response.attendance_report);

        setDropdown(response.attendance_report);

        if (response.attendance_report === null) {
          setNoEmployeePresent(true);
          setAttendanceReport([]);
        } else {
          const filteredReport = response.attendance_report.filter(
            (item) =>
              (item.username.toLowerCase().includes(username.toLowerCase()) ||
                username === "") && // Check if username matches or is empty
              (item.department === selectedDepartment ||
                selectedDepartment === "")
          );

          if (filteredReport.length === 0) {
            setNoEmployeePresent(true);
            setAttendanceReport([]);
          } else {
            setAttendanceReport(filteredReport);
            setBackendMessage(response.message);
            setSearchClicked(true);
            if (filteredReport.length > 0) {
              const firstDate = new Date(filteredReport[0]?.date);
              setDayOfWeek(
                firstDate.toLocaleDateString("en-US", { weekday: "long" })
              );
            }
          }
        }
      } catch (error) {
        // console.error("Error fetching attendance report:", error);
        setBackendMessage(
          "Error fetching attendance report. Please try again."
        );
      } finally {
        setLoading(false);
      }
    } else {
      if (!fromDate) {
        setFromDateValidationError("Please select 'From' date.");
      } else {
        setFromDateValidationError(null);
      }

      if (!toDate) {
        setToDateValidationError("Please select 'To' date.");
      } else {
        setToDateValidationError(null);
      }
    }
  }

  // Your existing date filtering logic
  const filteredData = attendanceReport.filter((reportItem) => {
    const currentDateValue = reportItem?.date;

    // Check if currentDateValue is truthy and is a valid date string
    const isValidDate =
      currentDateValue && typeof currentDateValue === "string";
    const currentDate = isValidDate ? new Date(currentDateValue) : null;

    return fromDate &&
      toDate &&
      !isNaN(currentDate?.getTime()) &&
      !isNaN(fromDate.getTime()) &&
      !isNaN(toDate.getTime())
      ? currentDate >= fromDate &&
          currentDate <= new Date(toDate.getTime() + 24 * 60 * 60 * 1000)
      : true;
  });
  const uniqueDatesSet = new Set<string>();

  const filteredArray: {
    username: string;
    details: Record<string, any>;
    department: string;
  }[] = [];
  // console.log("Filtered Data:", filteredData);

  // const uniqueDatesSet = new Set<string>();

  // Your provided attendance report
  // const filteredArray = [];

  // Iterate through the filteredData (filtered by date)
  filteredData.forEach((entry) => {
    const {
      date,
      username,
      checkIn,
      checkOut,
      logHours,
      outDoorHours,
      department,
    } = entry;

    // Add the date to the set for unique dates
    uniqueDatesSet.add(date);

    // Check if there is an existing entry for the username
    const existingEntry = filteredArray.find(
      (item) => item.username === username
    );

    if (existingEntry) {
      // If an entry already exists, add the date details
      existingEntry.details[date] = {
        checkIn,
        checkOut,
        logHours,
        outDoorHours,
        department,
      };
    } else {
      // If no entry exists, create a new entry for the username
      filteredArray.push({
        username,
        department,
        details: {
          [date]: { checkIn, checkOut, logHours, outDoorHours },
        },
      });
    }
    filteredArray.sort((a, b) => a.department.localeCompare(b.department));
    // console.log("filter array", filteredArray);
  });

  const handleDownloadExcel = () => {
    if (filteredArray && filteredArray.length > 0) {
      const companyName = sessionStorage.getItem("orgName");

      const uniqueDates = Array.from(
        new Set(
          filteredArray.reduce(
            (dates, reportItem) => [
              ...dates,
              ...Object.keys(reportItem.details),
            ],
            []
          )
        )
      );
      const uniqueDatesWithDayOfWeek = uniqueDates.map((date) => ({
        date,
        dayOfWeek: new Date(date).toLocaleDateString("en-US", {
          weekday: "long",
        }),
      }));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("AttendanceReport");

      worksheet.mergeCells("A1:H1");
      const companyCell = worksheet.getCell("A1");
      companyCell.value = `${companyName} (Attendance Report)`;
      companyCell.font = { size: 12, bold: true };
      companyCell.alignment = { horizontal: "center", vertical: "middle" };

      let currentRow = 2;

      worksheet.getCell(`A${currentRow}`).value = "Department";
      worksheet.getCell(`B${currentRow}`).value = "Name";
      worksheet.getCell(`C${currentRow}`).value = "Logged Time";

      worksheet.getCell(`A${currentRow}`).font = { bold: true };
      worksheet.getCell(`B${currentRow}`).font = { bold: true };
      worksheet.getCell(`C${currentRow}`).font = { bold: true };
      // Add days of the week below "Logged Time" column

      // Add date headers
      uniqueDates.forEach((date, index) => {
        const formattedDate = new Date(date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });

        const cell = worksheet.getCell(
          `${getExcelColumnLetter(index + 4)}${currentRow}`
        );
        cell.value = formattedDate;

        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "00FF00" },
        };
      });

      const daysHeaderCell = worksheet.getCell(`C${currentRow + 1}`);
      daysHeaderCell.value = "Days";
      daysHeaderCell.font = { bold: true };

      currentRow += 1;
      uniqueDatesWithDayOfWeek.forEach((dateInfo, index) => {
        const dayOfWeek = dateInfo.dayOfWeek;
        const dayOfWeekCell = worksheet.getCell(
          `${getExcelColumnLetter(index + 4)}${currentRow}`
        );
        dayOfWeekCell.value = dayOfWeek;
        dayOfWeekCell.font = { bold: true };

        dayOfWeekCell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        dayOfWeekCell.alignment = { horizontal: "center", vertical: "middle" };
      });

      currentRow += 1;

      // Track encountered departments
      const encounteredDepartments = {};

      filteredArray.forEach((reportItem) => {
        const department = reportItem.department;

        // Print department name only once, then leave it blank
        if (!encounteredDepartments[department]) {
          worksheet.getCell(`A${currentRow}`).value = department;
          encounteredDepartments[department] = true;
        } else {
          worksheet.getCell(`A${currentRow}`).value = "";
        }

        worksheet.getCell(`B${currentRow}`).value = reportItem.username;
        worksheet.getCell(`C${currentRow}`).value = "Check In";
        worksheet.getCell(`C${currentRow + 1}`).value = "Check Out";
        worksheet.getCell(`C${currentRow + 2}`).value = "Login Hours";
        worksheet.getCell(`C${currentRow + 3}`).value = "Out Door Hours";
        worksheet.getCell(`A${currentRow + 4}`).value = "";

        worksheet.getCell(`A${currentRow}`).font = { bold: true };
        worksheet.getCell(`C${currentRow}`).font = { bold: true };
        worksheet.getCell(`C${currentRow + 1}`).font = { bold: true };
        worksheet.getCell(`C${currentRow + 2}`).font = { bold: true };
        worksheet.getCell(`C${currentRow + 3}`).font = { bold: true };

        // Add date details
        let currentColumn = 4;
        uniqueDates.forEach((date) => {
          const detail = reportItem.details[date] || {
            checkIn: "",
            checkOut: "",
            logHours: "",
            outDoorHours: "",
          };

          // Add details for each date
          worksheet.getCell(
            `${getExcelColumnLetter(currentColumn)}${currentRow}`
          ).value = detail.checkIn;
          worksheet.getCell(
            `${getExcelColumnLetter(currentColumn)}${currentRow + 1}`
          ).value = detail.checkOut;
          worksheet.getCell(
            `${getExcelColumnLetter(currentColumn)}${currentRow + 2}`
          ).value = detail.logHours;
          worksheet.getCell(
            `${getExcelColumnLetter(currentColumn)}${currentRow + 3}`
          ).value = detail.outDoorHours;
          worksheet.getCell(
            `${getExcelColumnLetter(currentColumn)}${currentRow + 4}`
          ).value = "";

          currentColumn++;
        });

        currentRow += 5;
      });

      const columnWidths = [20, 20, ...Array(uniqueDates.length * 3).fill(13)];
      worksheet.columns.forEach((column, index) => {
        column.width = columnWidths[index];
      });

      // Center align the entire worksheet
      worksheet.views = [
        {
          state: "frozen",
          ySplit: 3,
          xSplit: 2,
        },
      ];

      // Save the workbook
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        if ((navigator as any).msSaveBlob) {
          // For Internet Explorer
          (navigator as any).msSaveBlob(blob, "AttendanceReport.xlsx");
        } else {
          // For other browsers
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "AttendanceReport.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    }
  };

  function getExcelColumnLetter(columnNumber) {
    let temp,
      letter = "";
    while (columnNumber > 0) {
      temp = (columnNumber - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      columnNumber = (columnNumber - temp - 1) / 26;
    }
    return letter;
  }

  return (
    <>
      <div className="page-wrapper ">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Employee</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Attendance Report</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-xl-2 ">
              <div className="input-block mb-3 form-focus">
                <input
                  type="text"
                  className="form-control floating labelsearch"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="e.g. ALEXA"
                />
                <label
                  className="focus-label labelfield"
                  style={{ color: "#32323e" }}
                >
                  Employee Name
                </label>
              </div>
            </div>
            {searchClicked && (
              <div className="col-sm-6 col-md-3 col-xl-2">
                {/* Department dropdown */}

                <div className="form-group mb-3">
                  <select
                    className="form-control form-focus"
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                  >
                    <option value="">Select Department</option>
                    {Array.isArray(dropdown) &&
                      dropdown.length > 0 &&
                      [...new Set(dropdown.map((item) => item.department))]
                        .filter(
                          (department) => department && department.trim() !== ""
                        )
                        .map((department, index) => (
                          <option key={index} value={department}>
                            {department}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-sm-6 col-md-3 col-xl-2 ">
              <div className="input-block mb-3 form-focus select-focus">
                <label
                  className="focus-label labelfield"
                  style={{ color: "#32323e" }}
                >
                  From Date
                </label>
                <DatePicker
                  className="inputfield wide-min"
                  selected={fromDate}
                  onChange={(date) => setFromDate(date)}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                  placeholderText="mm/dd/yyyy"
                />
                {fromDateValidationError && (
                  <div className="error-message" style={{ color: "red" }}>
                    {fromDateValidationError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-xl-2">
              <div className="input-block mb-3 form-focus select-focus">
                <label
                  className="focus-label labelfield lab"
                  style={{ color: "#32323e" }}
                >
                  To Date
                </label>
                <DatePicker
                  className="inputfield wide-min"
                  selected={toDate}
                  onChange={(date) => setToDate(date)}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                  placeholderText="mm/dd/yyyy"
                />
                {toDateValidationError && (
                  <div className="error-message" style={{ color: "red" }}>
                    {toDateValidationError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-xl-2 ">
              <div className="d-grid ">
                <button
                  className="btn btn-success btn-sm wide-min"
                  onClick={handleSearch}
                  disabled={!fromDate || !toDate}
                >
                  Search
                </button>
              </div>
            </div>
            {searchClicked && (
              <div className="col-sm-6 col-md-3 col-xl-2">
                <div className="d-grid pt-xs-3">
                  <button
                    className="btn btn-info"
                    style={{
                      color: "white",
                      backgroundColor: "#0c1d4c",
                      fontSize: "13px",
                    }}
                    onClick={handleDownloadExcel}
                  >
                    Export as Excel
                  </button>
                </div>
              </div>
            )}
          </div>

          {noEmployeePresent && (
            <div
              className="error-message"
              style={{ color: "red", marginTop: "10px" }}
            >
              No Employee Present
            </div>
          )}

          {backendMessage && (
            <div
              className="success-message"
              style={{ color: "green", marginTop: "10px" }}
            >
              {backendMessage}
            </div>
          )}

          <div className="row ">
            <div className="col-md-12 ">
              {loading ? (
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-30%, -30%)",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <ClockLoader color="#0c1d4c" loading={loading} size={60} />
                    <h2 style={{ marginTop: "10px", color: "#0c1d4c" }}>
                      Please wait while your report is loading...
                    </h2>
                  </div>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-striped custom-table mb-0 datatable">
                    <thead>
                      <tr>
                        <th style={{ paddingLeft: "30px" }}>Employee</th>
                        <th style={{ paddingLeft: "30px" }}>Department</th>
                        <th style={{ paddingLeft: "30px" }}>Logged</th>

                        {[
                          ...new Set(
                            attendanceReport.map((date) => date?.date)
                          ),
                        ].map((date, i) => (
                          <th key={i}>
                            {new Date(date).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            })}
                          </th>
                        ))}
                        {/* <th style={{ paddingLeft: "25px" }}>In Time</th>
                      <th style={{ paddingLeft: "20 px" }}>Out Time</th> */}
                        {/* <th>Login Hours</th> */}
                        {/* <th>Status</th> */}
                      </tr>

                      <tr>
                        <th></th>
                        <th></th>
                        <th style={{ paddingLeft: "30px" }}>Days</th>

                        {[
                          ...new Set(
                            attendanceReport.map((date) => date?.date)
                          ),
                        ].map((date, i) => (
                          <th key={i}>
                            {new Date(date).toLocaleDateString("en-US", {
                              weekday: "long",
                            })}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    {filteredArray && filteredArray.length > 0 ? (
                      <tbody>
                        {filteredArray
                          .filter((reportItem) => {
                            return username.toLowerCase() === " "
                              ? reportItem
                              : reportItem.username
                                  .toLowerCase()
                                  .includes(username.toLowerCase());
                          })
                          .map((reportItem, index) => (
                            <tr key={index}>
                              <td>
                                <a>{reportItem?.username}</a>
                              </td>
                              <td>{reportItem?.department}</td>
                              <td>
                                <tr>
                                  <strong>In Time:</strong>
                                </tr>
                                <tr>
                                  <strong>Out Time:</strong>
                                </tr>
                                <tr>
                                  <strong>Login Hrs:</strong>{" "}
                                </tr>
                                <tr>
                                  <strong>Out Door Hrs:</strong>{" "}
                                </tr>
                              </td>

                              {Object.keys(reportItem.details).map(
                                (date, i) => (
                                  <td key={i}>
                                    <tr>{reportItem.details[date].checkIn}</tr>
                                    <tr>{reportItem.details[date].checkOut}</tr>
                                    <tr>{reportItem.details[date].logHours}</tr>
                                    <tr>
                                      {reportItem.details[date].outDoorHours}
                                    </tr>
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={6} style={{ textAlign: "center" }}>
                            {attendanceReport === null
                              ? "No Employee Present"
                              : "No matching records found"}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendenceReport;
