import { useEffect, useState } from "react";
import {
  fetchProject,
  fetchProjectOverview,
} from "../API/controller/tts_projects";

interface Project {
  id: number;
  projectName: string;
  taskName: string;
  taskNo: string;
  assignee: string; // Add this line
  assigneeTo: string;
  taskStatus: string;
  taskInfo: any;
  length: any;
}

const TaskStatistics: React.FC = () => {
  const [taskList, setTaskList] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<number | null>(null); // Set the default project ID
  const [project, setProject] = useState<Project | null>(null);
  // @ts-ignore
  const [totalTask, setTotalTask] = useState<Project | null>(null);

  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        const response = await fetchProject();

        setTaskList(response?.projects);
      } catch (error) {
        console.error("Error fetching task list:", error);
      }
    };

    fetchProjectList();
  }, []);

  useEffect(() => {
    if (selectedProject !== null) {
      const fetchStatus = async () => {
        try {
          // console.log("selected project", selectedProject);
          const statusResponse = await fetchProjectOverview(selectedProject);
          // console.log("Project length:", statusResponse?.taskInfo?.length);
          setProject(statusResponse);
          setTotalTask(statusResponse?.taskInfo.length);
          // console.log("project state me", totalTask);
          // Assuming the statusResponse is a string
        } catch (error) {
          console.error("Error fetching project status:", error);
        }
      };

      fetchStatus();
    }
  }, [selectedProject]);

  return (
    <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
      <div className="card flex-fill">
        <div className="card-body background-imgcolor">
          <h4 className="card-title text-center">Task Statistics</h4>
          <div className="statistics mx-auto">
            <div className="mb-3">
              {/* <div className="col-md-6 col-6 mx-auto text-center">
                <div className="stats-box mb-4">
                  <p>Total Project</p>
                  <h3>{taskList?.length}</h3>
                </div>
              </div> */}

              <select
                id="projectDropdown"
                className="form-select"
                onChange={(e) => setSelectedProject(Number(e.target.value))}
                value={selectedProject || ""}
              >
                <option value="" disabled>
                  Select a project
                </option>
                {taskList.map((project) => (
                  <option key={project?.id} value={project?.id}>
                    {project?.projectName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* <div className="progress mb-4">
            <div
              className="progress-bar bg-purple w-30"
              role="progressbar"
              aria-valuenow={30}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              30%
            </div>
            <div
              className="progress-bar bg-warning w-22"
              role="progressbar"
              aria-valuenow={18}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              22%
            </div>
            <div
              className="progress-bar bg-success w-24"
              role="progressbar"
              aria-valuenow={12}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              24%
            </div>
            <div
              className="progress-bar bg-danger w-21"
              role="progressbar"
              aria-valuenow={14}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              21%
            </div>
            <div
              className="progress-bar bg-info w-10"
              role="progressbar"
              aria-valuenow={14}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              10%
            </div>
          </div> */}
          {/* <div>
            <p>
              <i className="fa-regular fa-circle-dot text-purple me-2" />
              Task Name
              <span className="float-end"> {project?.taskName}</span>
            </p>
            <p>
              <i className="fa-regular fa-circle-dot text-warning me-2" />
              Task No <span className="float-end">{project?.taskNo}</span>
            </p>
            <p>
              <i className="fa-regular fa-circle-dot text-success me-2" />
              Assignee <span className="float-end">{project?.assignee}</span>
            </p>
            <p>
              <i className="fa-regular fa-circle-dot text-danger me-2" />
              Assignee To{" "}
              <span className="float-end">{project?.assigneeTo}</span>
            </p>
            <p className="mb-0">
              <i className="fa-regular fa-circle-dot text-info me-2" />
              Task Status{" "}
              <span className="float-end">{project?.taskStatus}</span>
            </p>
          </div> */}
          {project ? (
            <div
              id="scrollbar1"
              style={{
                overflowY: "auto",
                maxHeight: "260px",
                paddingRight: "15px",
              }}
            >
              {/* Display all taskInfo values */}
              {project?.taskInfo?.map((task, index) => (
                <div key={index}>
                  <p className="mb-4">
                    <i className="fa-regular fa-circle-dot text-purple me-2" />
                    Task Name{" "}
                    <span className="float-end">{task?.taskName}</span>
                  </p>
                  <p className="mb-4">
                    <i className="fa-regular fa-circle-dot text-warning me-2" />
                    Task No <span className="float-end">{task?.taskNo}</span>
                  </p>
                  <p className="mb-4">
                    <i className="fa-regular fa-circle-dot text-success me-2" />
                    Assignee <span className="float-end">{task?.assignee}</span>
                  </p>
                  <p className="mb-4">
                    <i className="fa-regular fa-circle-dot text-danger me-2" />
                    Assignee To{" "}
                    <span className="float-end">{task?.assigneeTo}</span>
                  </p>
                  <p className="mb-4">
                    <i className="fa-regular fa-circle-dot text-cyan me-2" />
                    Accountable{" "}
                    <span className="float-end">{task?.accountable}</span>
                  </p>
                  <p className="mb-4">
                    <i className="fa-regular fa-circle-dot text-info me-2" />
                    Task Status{" "}
                    <span className="float-end mb-2">{task?.taskStatus}</span>
                  </p>
                  <hr />
                </div>
              ))}
            </div>
          ) : (
            <h1 className="text-center text-secondary pt-5">
              Please select a project.
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskStatistics;
