import React, { useEffect, useState } from "react";
import { fetchUserList } from "../API/controller/userlist";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { RingLoader } from "react-spinners";

interface Employee {
  id: string;
  employee_id: string;
  email: string;
  location: string;
  name: string;
  username: string;
  department: string;
}

const EmployeeList: React.FC = () => {
  const [data, setData] = useState<Employee[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // @ts-ignore
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const response = await fetchUserList();
        setData(response);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleDownloadpdf = () => {
    if (data.length > 0) {
      const downloadList = data.map((user) => [
        user?.name,
        user?.employee_id,
        user?.email ? user?.email : user?.username,
        user?.location,
        user?.department,
      ]);
      const companyName = sessionStorage.getItem("orgName");

      const pdf = new jsPDF() as any;
      pdf.text("Employee List", 80, 10);
      pdf.setFontSize(13);
      pdf.text("Company Name: " + companyName, 12, 20);
      pdf.setFontSize(10);
      pdf.autoTable({
        head: [
          ["Name", "EmployeeID", "Email/Username", "Location", "Department"],
        ],
        body: downloadList,
        startY: 22,
      });
      pdf.save("EmployeeList.pdf");
    }
  };

  // Filtered and sorted data
  const filteredData = data.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // console.log(filteredData);

  const sortedData = filteredData.sort((a, b) =>
    a.employee_id.localeCompare(b.employee_id)
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Employee</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Employee</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <div className="input-block">
                <label className="focus-label"></label>
                <input
                  type="text"
                  className="form-control floating"
                  placeholder="Search employee"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 mt-3">
              <button className="btn btn-success w-100">Search</button>
            </div>
            <div className="col-sm-6 col-md-3 mt-3">
              <button
                className="btn btn-info w-100 "
                style={{ color: "white", backgroundColor: "#0c1d4c" }}
                onClick={handleDownloadpdf}
              >
                Download PDF
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {loading ? (
                  <RingLoader
                    style={{
                      position: "fixed",
                      top: "60%",
                      left: "50%",
                      transform: "translate(-30%, -30%)",
                      textAlign: "center",
                    }}
                    color="#0c1d4c"
                  />
                ) : (
                  <>
                    <table className="table table-striped custom-table datatable">
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: "70px" }}>Name</th>
                          <th>Employee ID</th>
                          <th>Email/Username</th>
                          <th>Location</th>
                          <th className="no-sort">Department</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((employee, index) => (
                          <tr key={index}>
                            <td>
                              <a style={{ paddingLeft: "40px" }}>
                                {employee?.name}
                              </a>
                            </td>
                            <td>{employee?.employee_id}</td>
                            <td>
                              <a>
                                {employee?.email
                                  ? employee?.email
                                  : employee?.username}
                              </a>
                            </td>
                            <td>{employee?.location}</td>
                            <td>{employee?.department}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* Pagination */}
                    {filteredData.length > itemsPerPage && (
                      <ul className="pagination d-flex justify-content-center">
                        {Array.from({
                          length: Math.ceil(filteredData.length / itemsPerPage),
                        }).map((_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                          >
                            <a
                              onClick={() => paginate(index + 1)}
                              className="page-link"
                              style={{ backgroundColor: "#0c1d4c" }}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
