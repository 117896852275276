import axiosInstance from "../Interceptor/interceptor";

export const fetchProject = async () => {
  try {
    const token = sessionStorage.getItem("token");

    if (!token) {
      // You might want to redirect to a login page here.
      console.error("Token is missing");
      return null;
    }

    const response = await axiosInstance.get("/tts/view-projects", {
      headers: {
        Authorization: `${token}`,
      },
    });

    // console.log("GET Request Response:", response.data);
    return response.data;
  } catch (error) {
    // Handle errors more gracefully (e.g., show a user-friendly message).
    console.error("GET Request Error:", error);
    throw error; // Propagate the error for potential further handling.
  }
};
export const fetchProjectOverview = async (selectedProjectId: number) => {
  const body = {
    projectid: selectedProjectId,
  };

  try {
    const response = await axiosInstance.post("/tts/project-overview", body);

    if (response.status===200) {
      // console.log("Project Overview Response:", response.data);
      return response.data;
    } else {
      console.error("Project Overview Failed:", response.data.message);
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("Project Overview Error:", error);
    throw error;
  }
};