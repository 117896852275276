import { useState, useEffect } from "react";
import "./App.css";
import SideBar from "./components/SideBar";
import AddEmployee from "./pages/AddEmployee";
import AdminDashboard from "./pages/AdminDashboard";
import EmployeeList from "./pages/EmployeeList";
// import Login from "./pages/Login";
import Login2 from "./pages/Login2";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./utils/PrivateRoutes";
import UserProfile from "./pages/UserProfile";
import EmployeeLeaveBal from "./pages/EmployeeLeaveBal";
import AttendenceReport from "./pages/AttendenceReport";
import { RingLoader } from "react-spinners";
import OutOfRange from "./pages/OutOfRange";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <RingLoader
              color="rgba(103, 58, 183)"
              loading={loading}
              size={120}
            />
          </div>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login2 />}></Route>
            <Route path="/" element={<SideBar />}>
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <AdminDashboard />
                  </PrivateRoute>
                }
              ></Route>
              {/* <Route
                path="admindashboard2"
                element={
                  <PrivateRoute>
                    <AdminDashboard2 />
                  </PrivateRoute>
                }
              ></Route> */}
              <Route
                path="addemployee"
                element={
                  <PrivateRoute>
                    <AddEmployee />
                  </PrivateRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                }
              />
              <Route
                path="employeelist"
                element={
                  <PrivateRoute>
                    <EmployeeList />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path="employeeleavebal"
                element={
                  <PrivateRoute>
                    <EmployeeLeaveBal />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path="attendencereport"
                element={
                  <PrivateRoute>
                    <AttendenceReport />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="outofrange"
                element={
                  <PrivateRoute>
                    <OutOfRange />
                  </PrivateRoute>
                }
              ></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
