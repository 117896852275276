import axiosInstance from "../Interceptor/interceptor.ts";

export const fetchdepartmentList = async () => {
  try {
    const response = await axiosInstance.get("/auth/department-list");
    // console.log("GET Request fetch department:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Request Error:", error);
  }
};
export const fetchlocation = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const response = await axiosInstance.get("/auth/location/get-locations", {
      headers: {
        Authorization: `${token}`,
      },
    });
    // console.log("GET Request fetch location:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Request Error:", error);
  }
};

export const fetchUserList = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is missing");
      return;
    }

    const response = await axiosInstance.get("/auth/user/users-list", {
      headers: {
        Authorization: `${token}`,
      },
    });

    // console.log("GET Request Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Request Error:", error);
  }
};
export const addEmployee = async (
  name: string,
  username: string,
  supervisor_id: number,
  department_id: number,
  usertype: string,
  locationid: number
) => {
  const body = {
    name: name,
    username: username,
    supervisor_id: supervisor_id,
    department_id: department_id,
    is_supervisor: usertype === "supervisor",
    is_admin: usertype === "admin",
    locationid: locationid,
  };

  return await axiosInstance.post("/auth/user/add-user", body);
};
// export const addEmployee = async (
//   name: string,
//   username: string,
//   supervisor_id: number,
//   department_id: number,
//   usertype: string,
//   locationid: number
// ) => {
//   const body = {
//     name: name,
//     username: username,
//     supervisor_id: supervisor_id,
//     department_id: department_id,
//     is_supervisior: usertype === "supervisor",
//     is_admin: usertype === "admin",
//     locationid: locationid,
//   };

//   try {
//     const response = await axiosInstance.post("/auth/user/add-user", body);

//     if (response.data.success) {
//       // Do something on success, if needed
//       console.log("User added successfully");
//     } else {
//       // Handle the error case
//       console.error("User addition failed:", response.data.message);
//     }
//   } catch (error) {
//     console.error("User addition error:", error);
//   }
// };
