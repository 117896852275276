import axiosInstance from "../Interceptor/interceptor";

export const fetchOutOfRange = async (
  fromDate: { toString: () => string } | null | undefined,
  toDate: { toString: () => string } | null | undefined
) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is missing");
      return;
    }

    const requestBody = {
      fromDate: fromDate != null ? fromDate.toString().split(" ")[0] : null,
      toDate: toDate != null ? toDate.toString().split(" ")[0] : null,
    };

    const response = await axiosInstance.post(
      "attendance/out-of-range-report",
      requestBody,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // console.log("report response", response.data);
    return response.data;
  } catch (error) {
    console.error("POST Request Error:", error);
  }
};

export type OutOfRangeType = {
  username: string;
  date: string;
  inTime: string;
  outTime: string;
  totalHours: string;
  //   outDoorHours: string;
  //   department: string;
  //   status: string;
};
