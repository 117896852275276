import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClockLoader } from "react-spinners";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import {
  fetchOutOfRange,
  OutOfRangeType,
} from "../API/controller/view-out-of-range";

const OutOfRange: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [attendanceReport, setAttendanceReport] = useState<OutOfRangeType[]>(
    []
  );
  const [fromDateValidationError, setFromDateValidationError] = useState<
    string | null
  >(null);
  const [toDateValidationError, setToDateValidationError] = useState<
    string | null
  >(null);
  const [noEmployeePresent, setNoEmployeePresent] = useState<boolean>(false);
  const [backendMessage, setBackendMessage] = useState<string | null>(null);

  async function handleSearch() {
    setNoEmployeePresent(false);
    setBackendMessage(null);
    setLoading(true);

    if (fromDate && toDate) {
      setFromDateValidationError(null);
      setToDateValidationError(null);

      try {
        const formattedFromDate = format(fromDate, "yyyy-MM-dd");
        const formattedToDate = format(toDate, "yyyy-MM-dd");

        const response = await fetchOutOfRange(
          formattedFromDate,
          formattedToDate
        );
        setAttendanceReport(response.out_of_range_report);

        if (
          !response.out_of_range_report ||
          response.out_of_range_report.length === 0 ||
          response.out_of_range_report.length === null
        ) {
          setNoEmployeePresent(true);
        } else {
          setBackendMessage(response.message);
        }
      } catch (error) {
        setBackendMessage("Out of Range Report not Found.");
      } finally {
        setLoading(false);
      }
    } else {
      if (!fromDate) {
        setFromDateValidationError("Please select 'From' date.");
      } else {
        setFromDateValidationError(null);
      }

      if (!toDate) {
        setToDateValidationError("Please select 'To' date.");
      } else {
        setToDateValidationError(null);
      }
    }
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Employee</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Out Of Range</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row filter-row">
          <div className="col-sm-6 col-md-3 col-xl-2 ">
            <div className="input-block mb-3 form-focus">
              <input
                type="text"
                className="form-control floating labelsearch"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="e.g. ALEXA"
              />
              <label
                className="focus-label labelfield"
                style={{ color: "#32323e" }}
              >
                Employee Name
              </label>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 col-xl-2 ">
            <div className="input-block mb-3 form-focus select-focus">
              <label
                className="focus-label labelfield"
                style={{ color: "#32323e" }}
              >
                From Date
              </label>
              <DatePicker
                className="inputfield wide-min"
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                dateFormat="MM/dd/yyyy"
                isClearable
                placeholderText="mm/dd/yyyy"
              />
              {fromDateValidationError && (
                <div className="error-message" style={{ color: "red" }}>
                  {fromDateValidationError}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-xl-2">
            <div className="input-block mb-3 form-focus select-focus">
              <label
                className="focus-label labelfield lab"
                style={{ color: "#32323e" }}
              >
                To Date
              </label>
              <DatePicker
                className="inputfield wide-min"
                selected={toDate}
                onChange={(date) => setToDate(date)}
                dateFormat="MM/dd/yyyy"
                isClearable
                placeholderText="mm/dd/yyyy"
              />
              {toDateValidationError && (
                <div className="error-message" style={{ color: "red" }}>
                  {toDateValidationError}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-xl-2 ">
            <div className="d-grid ">
              <button
                className="btn btn-success btn-sm wide-min"
                onClick={handleSearch}
                disabled={!fromDate || !toDate}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        {noEmployeePresent && (
          <div
            className="error-message"
            style={{ color: "red", marginTop: "10px" }}
          >
            No Data Present
          </div>
        )}

        {backendMessage && (
          <div
            className="success-message"
            style={{ color: "green", marginTop: "10px" }}
          >
            {backendMessage}
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            {loading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-30%, -30%)",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ClockLoader color="#0c1d4c" loading={loading} size={60} />
                  <h2 style={{ marginTop: "10px", color: "#0c1d4c" }}>
                    Please wait while your report is loading...
                  </h2>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-striped custom-table mb-0 datatable">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Date</th>
                      <th>In Time</th>
                      <th>Out Time</th>
                      <th>Total Hours</th>
                      {/* Include other fields as needed */}
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceReport !== null ? (
                      attendanceReport.map((reportItem, index) => (
                        <tr key={index}>
                          <td>{reportItem?.username}</td>
                          <td>{reportItem?.date}</td>
                          <td>{reportItem?.inTime}</td>
                          <td>{reportItem?.outTime}</td>
                          <td>{reportItem?.totalHours}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} style={{ textAlign: "center" }}>
                          No Employee Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutOfRange;
