import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "../App.css";
import { addLeaves } from "../API/controller/add_leaves";
import { fetchEmpLeaveBal } from "../API/controller/emp_leave_balance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

interface Leave {
  userid: number;
  userName: string;
  cl: number;
  co: number;
  pl: number;
  sl: number;
}

interface LeavesProps {}

const EmployeeLeaveBal: React.FC<LeavesProps> = () => {
  const itemsPerPage: number = 10;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [editingUserId, setEditingUserId] = useState<number | null>(null);
  const [editedLeave, setEditedLeave] = useState<Leave | null>(null);
  const [leaveBal, setLeaveBal] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchEmpLeaveBal();
        // console.log("API response:", response);

        // Check if response is an array
        setLeaveBal(response.leaveBalance);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUser();
  }, []);

  if (!leaveBal || leaveBal.length === 0) {
    return (
      <div>
        <h2>Leave Balance</h2>
        <p>No leave balances available.</p>
      </div>
    );
  }

  const indexOfLastItem: number = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleEditClick = (userid: number, leave: Leave) => {
    setEditingUserId(userid);
    setEditedLeave({ ...leave });
  };

  const handleSaveClick = async () => {
    try {
      if (editedLeave) {
        // console.log("Saving leaves:", editedLeave);

        // Save the edited leave data
        await addLeaves({
          user_id: editedLeave.userid,
          cl: editedLeave.cl,
          co: editedLeave.co,
          pl: editedLeave.pl,
          sl: editedLeave.sl,
        });

        // Fetch the updated leave balance data for the specific user
        // const updatedLeaveData = await fetchEmpLeaveBal();

        // Update the state with the new leave data
        setLeaveBal((prevLeaves) => {
          const index = prevLeaves.findIndex(
            (leave) => leave.userid === editedLeave.userid
          );

          const newLeaves = [...prevLeaves];
          newLeaves[index] = editedLeave;

          // Log the values for debugging
          // console.log("Updated leaveBal:", newLeaves);

          toast.success("Leave data updated successfully!");

          return newLeaves;
        });

        setEditingUserId(null);
        setEditedLeave(null);
      }
    } catch (error) {
      console.error("Error adding leaves:", error);

      // Show error toast
      toast.error("Error updating leave data!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: keyof Leave
  ) => {
    const inputValue = parseInt(e.target.value);

    setEditedLeave((prevEditedLeave) => ({
      ...(prevEditedLeave as Leave),
      [type]: isNaN(inputValue) ? 0 : inputValue,
    }));
  };

  const sortedLeaveBal = leaveBal
    .filter((leave) =>
      leave.userName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.userName.localeCompare(b.userName));

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Employee</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Employee Master</Link>
                </li>
                <li className="breadcrumb-item active">Leave Balance</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row filter-row">
          <div className="col-sm-6 col-md-3">
            <div className="input-block">
              <label className="focus-label"></label>
              <input
                type="text"
                className="form-control floating"
                placeholder="Search employee"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-6 col-md-3 mt-3">
            <button className="btn btn-success w-100">Search</button>
          </div>
          <h2>Leave Balance</h2>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-striped custom-table mb-0 datatable">
                <thead>
                  <tr>
                    <th style={{ paddingLeft: "60px" }}>Employee</th>
                    <th>CL</th>
                    <th>CO</th>
                    <th>PL</th>
                    <th>SL</th>
                    {/* <th>Reason</th> */}
                    {/* <th className="text-center">Status</th> */}
                    <th className="text-end">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {sortedLeaveBal
                    .slice(indexOfFirstItem, indexOfLastItem)
                    .map((leave) =>
                      leave.userName.trim() !== "" ? (
                        <tr key={leave.userid}>
                          <td>
                            <h2>
                              <a className="avatar"></a>
                              <a>{leave.userName}</a>
                            </h2>
                          </td>
                          <td>
                            {editingUserId === leave.userid ? (
                              <input
                                type="text"
                                value={editedLeave?.cl}
                                onChange={(e) => handleInputChange(e, "cl")}
                                style={{ width: "50px" }}
                              />
                            ) : (
                              <>{leave.cl}</>
                            )}
                          </td>
                          <td>
                            {editingUserId === leave.userid ? (
                              <input
                                type="text"
                                value={editedLeave?.co}
                                onChange={(e) => handleInputChange(e, "co")}
                                style={{ width: "50px" }}
                              />
                            ) : (
                              <>{leave.co}</>
                            )}
                          </td>
                          <td>
                            {editingUserId === leave.userid ? (
                              <input
                                type="text"
                                value={editedLeave?.pl}
                                onChange={(e) => handleInputChange(e, "pl")}
                                style={{ width: "50px" }}
                              />
                            ) : (
                              <>{leave.pl}</>
                            )}
                          </td>
                          <td>
                            {editingUserId === leave.userid ? (
                              <input
                                type="text"
                                value={editedLeave?.sl}
                                onChange={(e) => handleInputChange(e, "sl")}
                                style={{ width: "50px" }}
                              />
                            ) : (
                              <>{leave.sl}</>
                            )}
                          </td>
                          <td className="text-end">
                            <div className="dropdown dropdown-action">
                              {editingUserId !== leave.userid ? (
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleEditClick(leave.userid, leave)
                                  }
                                >
                                  <i className="fa-solid fa-pencil m-r-5" />{" "}
                                  Edit
                                </button>
                              ) : (
                                <button
                                  className="dropdown-item"
                                  onClick={handleSaveClick}
                                >
                                  <i className="fa-solid fa-check m-r-5" /> Save
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ) : null
                    )}
                </tbody>
              </table>
            </div>
            <div className="pagination-container">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(leaveBal.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                // containerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeLeaveBal;
