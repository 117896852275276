import { ReactNode } from "react";
import axiosInstance from "../Interceptor/interceptor";

export const fetchAttendenceReport = async (
  fromDate: { toString: () => string } | null | undefined,
  toDate: { toString: () => string } | null | undefined
) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is missing");
      return;
    }

    const requestBody = {
      fromDate: fromDate != null ? fromDate.toString().split(" ")[0] : null,
      toDate: toDate != null ? toDate.toString().split(" ")[0] : null,
      userId: 0,
    };

    const response = await axiosInstance.post(
      "attendance/attendancereport",
      requestBody,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // console.log("report response", response.data);
    return response.data;
  } catch (error) {
    console.error("POST Request Error:", error);
  }
};

export type AttendanceReportItem = {
  currentDate: ReactNode;
  avatar: string;
  username: string;
  jobTitle: string;
  date: string;
  checkIn: string;
  checkOut: string;
  logHours: string;
  outDoorHours: string;
  department: string;
  status: string;
};
