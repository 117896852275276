import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  fetchdepartmentList,
  fetchlocation,
  addEmployee,
  fetchUserList,
} from "../API/controller/adduser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import "../App.css";
import "../addemployee.css";
// Define DepartmentType and LocationType interfaces based on your API response
interface DepartmentType {
  ID: number;
  DepartmentName: string;
}

interface LocationType {
  ID: number;
  AddressName: string;
}

interface TenantType {
  id: number;
  name: string;
}

const AddEmployee = () => {
  const [department, setDepartment] = useState<DepartmentType[]>([]);
  const [location, setLocation] = useState<LocationType[]>([]);
  const [tenant, setTenant] = useState<TenantType[]>([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    ename: "",
    username: "",
    department: "",
    location: "",
    supervisor: "",
    usertype: "user",
  };

  const validationSchema = Yup.object().shape({
    ename: Yup.string().required("Employee Name is required"),
    username: Yup.string().required("Username/Email is required"),
    department: Yup.number().required("Department is required"),
    location: Yup.number().required("Location is required"),
    supervisor: Yup.number().required("Supervisor is required"),
    usertype: Yup.string().required("User Type is required"),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const departmentData = await fetchdepartmentList();
        const locationData = await fetchlocation();
        const tenantData = await fetchUserList();

        setDepartment(departmentData.departments);
        setLocation(locationData.office_locations);
        setTenant(tenantData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="account-page background-imgcolor pt-4 ">
        <div className="main-wrapper">
          <div className="account-content ">
            <div className="container">
              <ToastContainer />
              <div className="account-box">
                <div className="account-wrapper">
                  {/* <div className="logo-container">
                    <img
                      src="https://dosrdm61w7ws7.cloudfront.net/App-icon/AIMS%20(20).png"
                      style={{ maxWidth: "100%", height: "135px" }}
                      alt="Company Logo"
                    />
                  </div> */}

                  <h3 className="account-title">Add Employee</h3>
                  <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                      try {
                        const response = await addEmployee(
                          values.ename,
                          values.username,
                          parseInt(values.supervisor),
                          parseInt(values.department),
                          values.usertype,
                          parseInt(values.location)
                        );
                        // console.log("heree", response?.data);
                        if (response?.status === 200) {
                          navigate("/employeelist"); // Redirect to /employeelist
                          setTimeout(() => {
                            toast.success("Employee added successfully!");
                          }, 100);
                          return response;
                        } else if (response.status === 403) {
                          toast.error("Employee subscription");
                        }
                      } catch (error) {
                        console.error("Error submitting form:", error);
                        // Handle error if needed
                        toast.error(error.response.data.error);
                      }
                    }}
                  >
                    {() => (
                      <Form>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Employee Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                className="form-control"
                                type="text"
                                id="ename"
                                name="ename"
                              />
                              <ErrorMessage
                                name="ename"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Username/Email{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                className="form-control"
                                type="text"
                                id="username"
                                name="username"
                              />
                              <ErrorMessage
                                name="username"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Department{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                name="department"
                                id="department"
                                as="select"
                                className="custom-dropdown"
                              >
                                <option value="" disabled>
                                  Select Department
                                </option>

                                {department?.map((dept) => (
                                  <option
                                    key={dept.ID}
                                    style={{ textTransform: "capitalize" }}
                                    value={dept.ID}
                                  >
                                    {dept.DepartmentName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="department"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Select Office Location{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                as="select"
                                name="location"
                                className="custom-dropdown"
                              >
                                <option value="" disabled>
                                  Select Location
                                </option>
                                {location?.map((loc) => (
                                  <option
                                    style={{ textTransform: "capitalize" }}
                                    key={loc.ID}
                                    value={loc.ID}
                                  >
                                    {loc.AddressName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="location"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Supervisor
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                as="select"
                                name="supervisor"
                                className="custom-dropdown"
                              >
                                <option value="" disabled>
                                  Select Supervisor
                                </option>
                                {tenant?.map((tent) => (
                                  <option
                                    style={{ textTransform: "capitalize" }}
                                    key={tent.id}
                                    value={tent.id}
                                  >
                                    {tent.name}
                                  </option>
                                ))}
                                ;
                              </Field>
                              <ErrorMessage
                                name="supervisor"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="input-block mb-3 row">
                              <label className="col-lg-3 col-form-label">
                                User Type:
                              </label>
                              <div
                                className="col-lg-9"
                                style={{
                                  paddingTop: "9px",
                                  paddingLeft: "9rem",
                                }}
                              >
                                <div className="form-check form-check-inline">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="usertype"
                                    value="user"
                                  />
                                  <label className="form-check-label">
                                    User
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="usertype"
                                    value="admin"
                                  />
                                  <label className="form-check-label">
                                    Admin
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="usertype"
                                    value="supervisor"
                                  />
                                  <label className="form-check-label">
                                    Supervisor
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="submit-section mb-4">
                          {error && <p className="error">{error}</p>}
                          <button
                            style={{ backgroundColor: "#0e1e4d" }}
                            type="submit"
                            className="btn btn-primary submit-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
