import axiosInstance from "../Interceptor/interceptor.ts";

export const fetchEmpLeaveReq = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is missing");
      return;
    }

    const response = await axiosInstance.get("/leaves/view-leave-request", {
      headers: {
        Authorization: `${token}`,
      },
    });

    // console.log("Get Emp Leave Request", response.data);
    return response.data;
  } catch (error) {
    console.error("GET Request Error:", error);
  }
};
export const fetchApproveLeaves = async (
  selectedLeaveID: string,
  isAccepted: boolean,
  isDenied: boolean
) => {
  const body = {
    leaveID: selectedLeaveID,
    approved: isAccepted,
    denied: isDenied,
  };
  try {
    const response = await axiosInstance.post("/leaves/approve-leaves", body);

    if (response.status === 200) {
      // console.log("response approve", response);
      return response;
    } else {
      console.error("User req failed", response.data.message);
      throw new Error(response.data.message); // Throw the error for the calling code to handle
    }
  } catch (error) {
    console.error("leave addition error:", error);
    throw error; // Throw the error for the calling code to handle
  }
};
