import axiosInstance from "../Interceptor/interceptor";

export const fetchTodaysPresent = async (selectedUserId: number = 0) => {
  const body = {
    userid: selectedUserId,
  };

  try {
    const response = await axiosInstance.post("/attendance/attendance-report", body);

    if(response.status=== 404 || response.status===400){
      return null;
    }
else if (response.status===200) {
      // console.log("Todays Present", response.data);
      return response.data;
    } else {
      console.error("Project Overview Failed:", response.data.message);
      throw new Error(response.data.message);
    }
    
  } catch (error) {
    console.error("Project Overview Error:", error);
    throw error;
  }
};