import axiosInstance from "../Interceptor/interceptor.ts";

interface Leave {
  user_id: number;
  cl: number;
  sl: number;
  pl: number;
  co: number;
}

export const addLeaves = async (leave: Leave) => {
  try {
    const response = await axiosInstance.post("/leaves/add-leaves", leave);

    if (response.data && response.status === 200) {
      // console.log("Leaves added successfully");
      // console.log("bal leave update", response.data);
      return response.data;
    } else {
      console.error(
        "Leaves addition failed:",
        response.data ? response.data.message : "No response data"
      );
      // Optionally, throw an error or return an error status
      throw new Error(
        response.data ? response.data.message : "Leaves addition failed"
      );
    }
  } catch (error) {
    console.error("Leaves addition error:", error);
    // Optionally, throw the error or handle it as needed
    throw error;
  }
};
