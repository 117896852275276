import { useEffect, useState } from "react";

import { fetchTodaysPresent } from "../API/controller/todays_present";

import "../App.css";

const TodaysAttendence: React.FC = () => {
  const [employeeName, setEmployeeName] = useState<string>("");
  const [presentReport, setPresentReport] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // const [data, setData] = useState<[]>([]);
  // @ts-ignore
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await fetchTodaysPresent();

        setPresentReport(response?.attendanceReport);
        setLoading(true);
        console.log("present", response);
        // setLoading(false);
      } catch (error) {
        setError(error.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchAttendance();
  }, []);

  // if(error){
  //   return <div>No employee Present today</div>
  // }

  if (loading) {
    return <div>Loading...</div>;
  }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  // const handleSearch = () => {
  //   const results = presentReport.filter((employee) =>
  //     employee.employeeName.toLowerCase().includes(employeeName.toLowerCase())
  //   );
  //   setPresentReport(results);
  // };

  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Today's Attendance</h3>
            </div>
          </div>
        </div>

        <div className="row filter-row">
          <div className="col-sm-6 col-md-3 col-xl-2 wide">
            <div className="input-block mb-3 form-focus">
              <input
                type="text"
                className="form-control floating labelsearch"
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
              />
              <label className="focus-label labelfield">Employee Name</label>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 col-xl-2 ">
            <div className="d-grid">
              <button className="btn btn-success btn-sm">Search</button>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12 " style={{ minHeight: "400px" }}>
            <div className="table-responsive">
              <table className="table table-striped custom-table mb-0 datatable">
                <thead>
                  <tr>
                    <th style={{ paddingLeft: "30px" }}>Employee</th>
                    <th style={{ paddingLeft: "30px" }}>Date</th>
                    <th style={{ paddingLeft: "25px" }}>In Time</th>
                    <th style={{ paddingLeft: "20px" }}>Out Time</th>
                    <th>Login Hours</th>
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {presentReport && presentReport.length > 0 ? (
                    presentReport
                      .filter((reportItem) => {
                        return employeeName.toLowerCase() === ""
                          ? reportItem
                          : reportItem.employeeName
                              .toLowerCase()
                              .includes(employeeName.toLowerCase());
                      })
                      .map((reportItem, index) => (
                        <tr key={index}>
                          <td>
                            <a>{reportItem?.employeeName}</a>
                          </td>
                          <td>{reportItem?.currentDate}</td>
                          <td>{reportItem?.inTime}</td>
                          <td>{reportItem?.outTime}</td>
                          <td>{reportItem?.loginHours}</td>
                          {/* <td>Present</td> */}
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={5} style={{ textAlign: "center" }}>
                        {presentReport === null
                          ? "No Employee Present"
                          : "No matching records found"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodaysAttendence;
