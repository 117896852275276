import { useEffect, useState } from "react";
import {
  fetchEmpLeaveReq,
  fetchApproveLeaves,
} from "../API/controller/approve_leaves";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface LeaveRequestProps {
  absentData: {
    projectsCount: number;
    totalAdmins: number;
    totalSupervisors: number;
    totalEmployees: number;
  };
}
const LeaveRequest: React.FC<LeaveRequestProps> = () => {
  const [leaveData, setLeaveData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchEmpLeaveReq();
        setLeaveData(data.leave_requests);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leave data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleApproveDeny = async (leaveID, isAccepted, isDenied) => {
    try {
      await fetchApproveLeaves(leaveID, isAccepted, isDenied);

      const newData = await fetchEmpLeaveReq();
      setLeaveData(newData.leave_requests);

      toast.success("Leave Request updated successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      console.error("Error approving/denying leave:", error);
      toast.error("Error approving/denying leave", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
      <div className="card flex-fill ">
        <div className="card-body background-imgcolor">
          <h4 className="card-title text-center">
            Employee Leave Request
            <span className="badge bg-inverse-danger ms-2"></span>
          </h4>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div
              id="scrollbar1"
              style={{
                overflowY:
                  leaveData && leaveData.length > 0 ? "auto" : "hidden",
                maxHeight: "360px",
                margin: "1px solid",
              }}
            >
              {leaveData && leaveData.length > 0 ? (
                leaveData.map((leaveItem) => (
                  <div key={leaveItem.ID} className="leave-info-box">
                    <div className="media d-flex align-items-center">
                      <a className="avatar">
                        <img
                          src="https://dosrdm61w7ws7.cloudfront.net/App-icon/logo2.png"
                          alt="User Image"
                        />
                      </a>
                      <div className="media-body flex-grow-1">
                        <div className="text-sm my-0">{leaveItem.UserName}</div>
                      </div>
                      <div>
                        <button
                          className="btn btn-sm btn-success me-2"
                          onClick={() =>
                            handleApproveDeny(leaveItem.ID, true, false)
                          }
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-sm btn-danger me-2"
                          onClick={() =>
                            handleApproveDeny(leaveItem.ID, false, true)
                          }
                        >
                          Deny
                        </button>
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6">
                        <h6 className="mb-0">
                          {leaveItem.FromDate} - {leaveItem.ToDate}
                        </h6>
                        <span className="text-sm text-muted">
                          {leaveItem.Reason}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                // <p >No leave requests available.</p>
                <div>
                  <h1 className=" text-center text-secondary mt-5 pt-5">
                    No leave requests available.
                  </h1>
                  {/* <div className="logo-container">
                    <img src="/src/assets/img/smart_aimsQR.png" />
                  </div> */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaveRequest;
