import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchTenantList, login } from "../API/controller/login";
import "../login.css";
import "../googlebutton.css";

interface Tenant {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  TenantName: string;
  Address: string;
  OrganisationEmail: string;
  OrganisationLogo: string;
}

const Login2 = () => {
  const [tenantList, setTenantList] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState("");
  const [selectedTenantId, setSelectedTenantId] = useState<number | null>(null);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [tenantError, setTenantError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  // @ts-ignore
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTenantList()
      .then((tenantData) => {
        setTenantList(tenantData);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching tenant list: " + error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedTenantId && email.trim() !== "" && password.trim() !== "") {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedTenantId, email, password]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!selectedTenantId) {
      setTenantError("Please select a company.");
      return;
    }

    if (!email.trim()) {
      setEmailError("Please enter your email / username.");
      return;
    }

    if (!password.trim()) {
      setPasswordError("Please enter your password.");
      return;
    }

    if (selectedTenantId) {
      // @ts-ignore
      const getData = await login(
        selectedTenantId,
        email,
        password,
        navigate,
        // @ts-ignore
        (message) => {
          // Handle success
        },
        (errorMessage) => {
          setError(errorMessage);
        }
      );
    } else {
      setError("Selected tenant not found");
    }
  };

  const clearSelectedTenant = () => {
    setSelectedTenant("");
    setSelectedTenantId(null);
    setReadOnly(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(null); // Clear email error when the email changes
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(null); // Clear password error when the password changes
  };

  const handleTenantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTenant(e.target.value);
    setTenantError(null); // Clear tenant error when the tenant selection changes

    const selectedTenantObject = tenantList.find(
      (tenant) => tenant.TenantName === e.target.value
    );
    if (selectedTenantObject) {
      setSelectedTenantId(selectedTenantObject.ID);
      setReadOnly(true);
    } else {
      setSelectedTenantId(null);
      setReadOnly(false);
    }
  };

  return (
    <>
      <div
        className="container1 container"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <div className="forms-container">
          <div className="signin-signup">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <form action="#" className="sign-in-form">
                <div className="logo-container">
                  <img
                    src="https://dosrdm61w7ws7.cloudfront.net/App-icon/logo2.png"
                    style={{ maxWidth: "150px", maxHeight: "100px" }}
                    alt="Company Logo"
                    className="non"
                  />
                </div>
                <h2 className="title">Login to Dashboard</h2>
                <div className="input-field">
                  <i className="fas fa-building" />
                  <div className="d-flex justify-content-between">
                    <input
                      placeholder="Search company"
                      list="emailOptions"
                      id="emailInput"
                      value={selectedTenant}
                      autoComplete="off"
                      onChange={handleTenantChange}
                      readOnly={readOnly}
                    />
                    {selectedTenant && (
                      <button
                        type="button"
                        className="btn btn1 solid "
                        style={{
                          width: "35px",
                          height: "35px",
                          position: "absolute",
                          right: "4px",
                        }}
                        onClick={clearSelectedTenant}
                      >
                        x
                      </button>
                    )}
                  </div>
                  <datalist id="emailOptions">
                    {tenantList.map((tenant) => (
                      <option
                        key={tenant.ID}
                        value={tenant.TenantName}
                        data-id={tenant.ID}
                      />
                    ))}
                  </datalist>
                </div>
                {tenantError && <p className="text-danger">{tenantError}</p>}
                <div className="input-field">
                  <i className="fas fa-user" />
                  <input
                    onChange={handleEmailChange}
                    type="text"
                    placeholder=" Email / Username"
                    autoComplete="username"
                  />
                </div>
                {emailError && <p className="text-danger">{emailError}</p>}
                <div className="input-field">
                  <i className="fas fa-lock" />
                  <div className="d-flex justify-content-between">
                    <input
                      placeholder=" Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      autoComplete="password"
                    />
                    <button
                      type="button"
                      className={`btn fa-solid btn1 ${
                        showPassword ? "fa-eye" : "fa-eye-slash"
                      }  password-show `}
                      id="toggle-password"
                      onClick={togglePasswordVisibility}
                      style={{
                        width: "35px",
                        height: "35px",
                        paddingLeft: "8px",
                        position: "absolute",
                        right: "4px",
                      }}
                    ></button>
                  </div>
                </div>
                {passwordError && (
                  <p className="text-danger">{passwordError}</p>
                )}
                <button
                  onClick={handleLogin}
                  type="submit"
                  // disabled={submitDisabled}
                  defaultValue="Login"
                  className="btn btn1 solid"
                >
                  Login
                </button>
                {error && <p className="text-danger">{error}</p>}
                <p className="social-text"> Download Now On</p>
                <div className="logo-container">
                  <ul>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/in/app/smart-aims/id6469505895"
                      className="storeLink"
                    >
                      <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                        alt="Download on the App Store"
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.smartaims.aims"
                      className="storeLink"
                    >
                      <img
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                        style={{ width: "128%", height: "45px" }}
                      />
                    </a>
                  </ul>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="panels-container">
          <div className="panel left-panel">
            <div className="content">
              <h3>Smart AIMS</h3>
              <p>Simplifying Attendance Tracking, Empowering Efficiency.</p>
              <h5>For Subscription Plan</h5>
              <button className="button btn btn1 bt2 hov" id="sign-up-btn">
                <a
                  href="https://smartaims.in/"
                  className="text-white hov"
                  target="_blank"
                >
                  Contact us
                </a>
              </button>
            </div>
            <img
              src="aims_mobile.png"
              style={{ margin: " auto" }}
              className="image1"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login2;
