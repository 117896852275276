import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom"; // Import Navigate from react-router-dom

export const PrivateRoute = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  // Use JSX.Element instead of jsx.element
  const { children } = props;
  const isloggedin: boolean = sessionStorage.getItem("token") !== null; // Use getItem instead of getitem
  const location = useLocation();

  return isloggedin ? (
    <>{children}</>
  ) : (
    <Navigate // Use the correct component name Navigate
      replace={true}
      to="/login"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};
